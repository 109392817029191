<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
						<el-form-item label="会议标题" prop="name">
							<el-input v-model="ruleForm.name"></el-input>
						</el-form-item>
						<!-- <el-form-item label="会议议题" prop="topics_id">
							<el-select v-model="ruleForm.topics_id" placeholder="请选择会议议题" style="width:475px;"
								clearable>
								<el-option :label="item.name" :value="item.id" v-for="(item,index) in hyytlist"
									:key="index"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item label="报名时间" required>
							<div style="display: flex;align-items: center;">
								<el-form-item prop="kstime">
									<el-date-picker v-model="ruleForm.kstime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择报名开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="endtime">
									<el-date-picker v-model="ruleForm.endtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择报名结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="会议时间" required>
							<div style="display: flex;align-items: center;">
								<el-form-item prop="addtime">
									<el-date-picker v-model="ruleForm.addtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="dqtime">
									<el-date-picker v-model="ruleForm.dqtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="签到时间" required="">
							<div style="display: flex;align-items: center;">
								<el-form-item prop="qdtime">
									<el-date-picker v-model="ruleForm.qdtime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择签到开始时间">
									</el-date-picker>
								</el-form-item>
								<div style="margin: 0 10px;">至</div>
								<el-form-item prop="jstime">
									<el-date-picker v-model="ruleForm.jstime" type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议结束时间">
									</el-date-picker>
								</el-form-item>
							</div>
						</el-form-item>
						<el-form-item label="会议详情" prop="content">							
							<div style="border: 1px solid #ccc;width: 100%;">
								<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
									mode="default" />
								<Editor style="height: 300px; overflow-y: hidden;font-size: 16px;" v-model="ruleForm.content"
									:defaultConfig="editorConfig" mode="default" @onCreated="onCreated" />
							</div>
						</el-form-item>
						<el-form-item label="打卡范围(米)" prop="fw">
							<el-input v-model="ruleForm.fw" type="number" style="width: 220px;"></el-input>
						</el-form-item>
						<el-form-item label="最大参加人数">
							<el-input v-model="ruleForm.max" type="number" style="width: 220px;"></el-input>
						</el-form-item>
						<el-form-item label="选择参会人员" required="">
							<div style="display: flex;align-items: center;">
								<el-button type="primary" size="small" @click="select">选择</el-button>
							</div>
							<div>
								<span style="color: #999;">已选人员：</span>{{user_name}}
							</div>
						</el-form-item>
						<el-form-item label="选择会议室">
							<div style="display: flex;align-items: center;">
								<div style="margin-right: 10px;">
									{{ruleForm.address}}
								</div>
								<el-button type="primary" size="small"
									@click="showroom">{{ruleForm.address?'重新选择':'选择会议室'}}</el-button>
							</div>
						</el-form-item>
						<el-form-item label="地图坐标" prop="zuobiao" style="width: 520px;">
							<el-input v-model="ruleForm.zuobiao" placeholder="输入经纬度,示例:116.37,40.31">
								<template slot="append">
									<el-button type="primary" @click="openmap">打开地图</el-button>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item label="短信通知">
							<div>
								<el-switch v-model="ruleForm.dxkg" :active-value="1" :inactive-value="0">
								</el-switch>
							</div>

						</el-form-item>
						<el-form-item label="报名开关">
							<div>
								<el-switch v-model="ruleForm.bmkg" :active-value="1" :inactive-value="0">
								</el-switch>
							</div>
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="backpage">取 消</el-button>
					<el-button type="success" @click="save">保 存</el-button>
					<el-button type="primary" @click="submitForm">发 布</el-button>
				</div>
			</div>
		</div>


		<selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>

		<selectroom ref="sleroom" @seletedroom="seletedroom"></selectroom>
	</div>

</template>

<script>
	import selectuser from '@/components/select_user.vue'
	import selectroom from './components/selectroom.vue'
	import {
		Message
	} from 'element-ui'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default {
		components: {
			selectuser,
			selectroom,
			Editor,
			Toolbar
		},
		data() {
			return {
				baseurl: "",
				ruleForm: {
					name: '',
					topics_id: '',
					kstime: '',
					endtime: '',
					addtime: '',
					dqtime: '',
					qdtime: '',
					jstime:'',
					content: '',
					chuser_ids: '',
					chuser_name: '',
					address: '',
					room_id: '',
					zuobiao: '',
					fw: '',
					max: '',
					dxkg: '',
					bmkg: '',
				},
				rules: {
					name: [{
						required: true,
						message: '请输入会议标题',
						trigger: 'blur'
					}],
					// topics_id: [{
					// 	required: true,
					// 	message: '请选择会议议题',
					// 	trigger: 'change'
					// }],
					kstime: [{
						required: true,
						message: '请选择报名开始时间',
						trigger: 'change'
					}],
					endtime: [{
						required: true,
						message: '请选择报名截止时间',
						trigger: 'change'
					}],
					addtime: [{
						required: true,
						message: '请选择会议开始时间',
						trigger: 'change'
					}],
					dqtime: [{
						required: true,
						message: '请选择会议结束时间',
						trigger: 'change'
					}],
					qdtime: [{
						required: true,
						message: '请选择签到开始时间',
						trigger: 'change'
					}],
					jstime: [{
						required: true,
						message: '请选择签到结束时间',
						trigger: 'change'
					}],
					content: [{
						required: true,
						message: '请填写会议详情',
						trigger: 'blur'
					}],
					fw: [{
						required: true,
						message: '请填写打卡范围',
						trigger: 'blur'
					}],
				},
				hyytlist: [], //会议议题数据
				user_id: '',
				user_name: '',
				
				
				//富文本相关
				editor: null,
				toolbarConfig: {},
				editorConfig: {
					placeholder: '请输入内容...',
					MENU_CONF: {
						uploadImage: {
							server: this.$URL + '/api/login/upload',
							fieldName: 'file',
							// 单个文件的最大体积限制，默认为 2M
							maximgSize: 10 * 1024 * 1024, // 10M
							// 最多可上传几个文件，默认为 100
							maxNumberOfimgs: 100,
							// 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
							allowedimgTypes: [],
							// 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
							meta: {
				
								// otherKey: 'yyy'
								// img:''
							},
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
				
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
				
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
				
							// 超时时间，默认为 10 秒
							timeout: 10 * 1000, //10 秒
				
							// 上传前
							onBeforeUpload(imgs) {
								Message({
									message: '图片正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "图片上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "图片上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
				
							// 单个文件上传成功之后
							onSuccess(img, res) {
								console.log(`${img.name} 上传成功`, res);
							},
				
							// 单个文件上传失败
							onFailed(img, res) {
								console.log(`${img.name} 上传失败`, res);
							},
				
							// 上传进度的回调函数
							onProgress(progress) {
								console.log('progress', progress);
								// progress 是 0-100 的数字
							},
				
							// 上传错误，或者触发 timeout 超时
							onError(img, err, res) {
								console.log(`${img.name} 上传出错`, err, res);
							}
						},
				
						// 上传视频
						uploadVideo: {
							server: this.$URL + '/api/login/upload',
							fieldName: "file",
							// 单个文件的最大体积限制，默认为 10M
							maxFileSize: 500 * 1024 * 1024, // 500M
							// 最多可上传几个文件，默认为 5
							maxNumberOfFiles: 3,
							// 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
							allowedFileTypes: ["video/*"],
							// 将 meta 拼接到 url 参数中，默认 false
							metaWithUrl: false,
							// 自定义增加 http  header
							headers: {
								token: this.$store.state.userinfo.token
								// otherKey: 'xxx'
							},
							// 跨域是否传递 cookie ，默认为 false
							withCredentials: false,
							// 超时时间，默认为 30 秒
							timeout: 30 * 1000, // 15 秒
							onBeforeUpload(imgs) {
								Message({
									message: '正在上传中,请耐心等待',
									grouping: true,
									duration: 0,
									customClass: 'uploadTip',
									iconClass: 'el-icon-loading',
									showClose: true
								});
								return imgs;
							},
							// 自定义插入图片
							customInsert(res, insertFn) {
								// 因为自定义插入导致onSuccess与onFailed回调函数不起作用,自己手动处理
								// 先关闭等待的ElMessage
								Message.closeAll();
				
								if (res.code === 200) {
									Message.success({
										message: "视频上传成功",
										grouping: true,
									});
								} else {
									Message.error({
										message: "上传失败，请重新尝试",
										grouping: true,
									});
								}
								// 从 res 中找到 url alt href ，然后插入图片
								insertFn(res.fullurl);
								// console.log(res, "res.data")
							},
							// 上传进度的回调函数
							onProgress(progress) {
								console.log("progress", progress);
							},
							// 单个文件上传成功之后
							onSuccess(file, res) {
								console.log(`${file.name} 上传成功`, res);
							},
							// 单个文件上传失败
							onFailed(file, res) {
								console.log(`${file.name} 上传失败`, res);
							},
							// 上传错误，或者触发 timeout 超时
							onError(file, err, res) {
								console.log(`${file.name} 上传出错`, err, res);
							}
						}
					}
				},
				
			}
		},
		created() {
			this.gethyytlist() //会议议题数据
		},
		methods: {
			save(){
				if(!this.ruleForm.name){
					this.$message.warning('请输入会议标题')
					return
				}
				this.ruleForm.chuser_ids = this.user_id
				this.ruleForm.chuser_name = this.user_name
				this.ruleForm.stype = 1
				this.$post({
					url: '/api/wymeeting/add',
					params: this.ruleForm
				}).then((res) => {
					this.$message.success('保存成功')
					this.$router.go(-1)
				})
			},
			submitForm() {
				if(!this.user_id){
					this.$message.warning('请选择参会人员')
					return
				}
				if(!this.ruleForm.address&&!this.ruleForm.zuobiao){
					this.$message.warning('请选择会议室或录入地图坐标')
					return
				}
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						this.ruleForm.chuser_ids = this.user_id
						this.ruleForm.chuser_name = this.user_name
						this.ruleForm.stype = 2
						this.$post({
							url: '/api/wymeeting/add',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('发布成功')
							this.$router.go(-1)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			openmap() {
				window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
			},
			seletedroom(e) {
				this.ruleForm.address = e.addr
				this.ruleForm.room_id = e.id
			},
			showroom() { //选择会议室
				if (!this.ruleForm.addtime) {
					this.$alert('请先选择会议开始时间', '提示', {
						confirmButtonText: '确定',
						callback: action => {

						}
					})
					return;
				}
				this.$refs.sleroom.radiovalue1 = this.ruleForm.room_id
				this.$refs.sleroom.addr = this.ruleForm.address
				this.$refs.sleroom.dialogVisible = true
				this.$refs.sleroom.starttime = this.ruleForm.addtime
				this.$refs.sleroom.getlist()
			},
			select() {
				let cxidlist = this.user_id ? this.user_id.split(',') : []
				let cxnamelist = this.user_name ? this.user_name.split(',') : []
				let list = []
				if (cxidlist.length > 0) {
					cxidlist.forEach((item, index) => {
						let obj = {
							name: cxnamelist[index],
							id: Number(item)
						}
						list.push(obj)
					})
				}
				this.$refs.myselectuser.selectuser = list.length > 0 ? list : []
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e) {
				this.user_id = e.map((item) => {
					return item.id
				})
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item) => {
					return item.name
				})
				this.user_name = this.user_name.join(',')
			},
			gethyytlist() {
				this.$post({
					url: '/api/topics/index',
					params: {
						ding: 6,
						p: 1,
						size: 999,
						qxtype: 1,
					}
				}).then((res) => {
					this.hyytlist = res.list
				})
			},
			backpage(){
				this.$router.go(-1)
			},
			onCreated(editor) {
				this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
			}
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>